<template>
    <div class="pc owner">
        
        <NavbarOwner :translator="translator" :user="user" class="m-bottom"/>
        
        <!-- Spiner -->
        <div v-if="isPreLoad" style="height: 100vh; display: flex; justify-content: center; align-items: center;">
            <div class="hollow-dots-spinner">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
            </div>
        </div>
        <!-- Fin spiner -->


        <div class="container px-4 py-3" v-if="booking.registered_guests">
            <div class="row">
                <div class="col pr-0">
                    <h5 @click="routerLink">
                        <i class="fal fa-file-alt pr-1"></i>
                        <span v-cloak> {{ translator.dadesreserva }}</span>
                    </h5>                
                </div>
            </div>

            <div class="row p-3" v-if="booking.arrival_date">
                <div class="col bg-white">

                    <table class="table table-sm">
                        <tbody>
                            <tr class="line-head-info" v-if="booking.lodging_name != ''">
                                <td class="">
                                    <b>{{ translator.propietat }}</b>
                                </td>
                                <td class="text-right">
                                    <b>{{ booking.lodging_name }}</b>
                                </td>
                            </tr>
                            <tr v-if="booking.reservation != ''">
                                <td>
                                    {{ translator.reserva }} ID
                                </td>
                                <td class="text-right">
                                    {{ booking.reservation }}
                                </td>
                            </tr>
                            <tr v-if="booking.agency_name != ''">
                                <td class="">
                                    {{ translator.agencia }}
                                </td>
                                <td class="text-right">
                                    {{ booking.agency_name }}
                                </td>
                            </tr>
                            <tr v-if="booking.agency_reservation != ''">
                                <td class="">
                                    {{ translator.localitzador }}
                                </td>
                                <td class="text-right">
                                    {{ booking.agency_reservation }}
                                </td>
                            </tr>



                            <tr v-if="booking.customer_first_name != ''">
                                <td>
                                    {{ translator.nom }}
                                </td>
                                <td class="text-right">
                                    {{ booking.customer_first_name }}
                                </td>
                            </tr>
                            <tr v-if="booking.customer_last_name != ''">
                                <td>
                                    {{ translator.cognoms }}
                                </td>
                                <td class="text-right">
                                    {{ booking.customer_last_name }}
                                </td>
                            </tr>
                            <tr v-if="booking.customer_email != ''">
                                <td>
                                    {{ translator.email }}
                                </td>
                                <td class="text-right">
                                    {{ booking.customer_email }}
                                </td>
                            </tr>
                            <tr v-if="booking.customer_phone != ''">
                                <td>
                                    {{ translator.telefon }}
                                </td>
                                <td class="text-right">
                                    {{ booking.customer_phone }}
                                </td>
                            </tr>
                            <tr v-if="booking.customer_address != ''">
                                <td>
                                    {{ translator.adreca }}
                                </td>
                                <td class="text-right">
                                    {{ booking.customer_address }}
                                </td>
                            </tr>
                            <tr v-if="booking.customer_city != ''">
                                <td>
                                    {{ translator.poble }}
                                </td>
                                <td class="text-right">
                                    {{ booking.customer_city }}
                                </td>
                            </tr>
                            <tr v-if="booking.customer_country != ''">
                                <td>
                                    {{ translator.pais }}
                                </td>
                                <td class="text-right">
                                    {{ booking.customer_country }}
                                </td>
                            </tr>
                            <tr v-if="booking.customer_zip != ''">
                                <td>
                                    {{ translator.dip }}
                                </td>
                                <td class="text-right">
                                    {{ booking.customer_zip }}
                                </td>
                            </tr>
                            <tr v-if="booking.customer_tin != ''">
                                <td>
                                    {{ translator.cif }}
                                </td>
                                <td class="text-right">
                                    {{ booking.customer_tin }}
                                </td>
                            </tr>

                            <tr v-if="booking.booking_date != ''">
                                <td class="">
                                    {{ translator.data }} {{ translator.reserva | toLower }}
                                </td>
                                <td class="text-right">
                                    {{ booking.booking_date | transformDate }}
                                </td>
                            </tr>
                            <tr v-if="booking.arrival_date != ''">
                                <td class="">
                                    {{ translator.arribada }}
                                </td>
                                <td class="text-right">
                                    {{ booking.arrival_date | transformDate }} 
                                    <span v-if="booking.arrival_time"> {{ booking.arrival_time }}h</span>
                                </td>
                            </tr>
                            
                            <tr v-if="booking.departure_date != ''">
                                <td class="">
                                    {{ translator.sortida }}
                                </td>
                                <td class="text-right">
                                    {{ booking.departure_date | transformDate }}
                                    <span v-if="booking.departure_time"> {{ booking.departure_time }}h</span>
                                </td>
                            </tr>
                            <tr v-if="booking.nights != ''">
                                <td class="">
                                    {{ translator.nits }}
                                </td>
                                <td class="text-right">
                                    {{ booking.nights }}
                                </td>
                            </tr>

                            <tr v-if="booking.adults != ''">
                                <td>
                                    {{ translator.adults }}
                                </td>
                                <td class="text-right">
                                    {{ booking.adults }}
                                </td>
                            </tr>
                            <tr v-if="booking.children != ''">
                                <td>
                                    {{ translator.nens }}
                                </td>
                                <td class="text-right">
                                    {{ booking.children }}
                                </td>
                            </tr>
                            <tr v-if="booking.babies != ''">
                                <td>
                                    {{ translator.bebes }}
                                </td>
                                <td class="text-right">
                                    {{ booking.babies }}
                                </td>
                            </tr>
                            <tr v-if="booking.customer_remarks != ''">
                                <td>
                                    {{ translator.observacions }}
                                </td>
                                <td class="text-right">
                                    {{ booking.customer_remarks }}
                                </td>
                            </tr> 
                            <tr v-if="booking.owner_remarks != ''">
                                <td>
                                    {{ translator.observacionspropietari }}
                                </td>
                                <td class="text-right">
                                    {{ booking.owner_remarks }}
                                </td>
                            </tr> 
                        </tbody>
                    </table>
                    
                </div>
            </div>
            <div class="row p-3" v-if="booking.arrival_date">
                <div class="col bg-white">

                    <table class="table table-sm">
                        <tbody>

                            <tr class="line-head-info" v-if="booking.lodging_name != ''">
                                <td class="">
                                    <b>{{ translator.propietat }}</b>
                                </td>
                                <td class="text-right">
                                    <b>{{ translator.import }}</b>
                                </td>
                            </tr>
                            
                            <!-- bucle -->
                            <tr v-for="det in bookingPayments.detail" :key="det.import">
                                <td>
                                    {{ det.text }}
                                </td>
                                <td class="text-right">
                                    {{ det.import | ereaseZero }} {{bookingPayments.currency}}
                                </td>
                            </tr>

                            <tr>
                                <td><span class="text-white">-</span></td>
                                <td></td>
                            </tr>

                            <tr>
                                <td>{{ translator.suma }}</td>
                                <td class="text-right">{{ bookingPayments.stay_amount | ereaseZero }} {{bookingPayments.currency}}</td>
                            </tr>

                            <tr>
                                <td><span class="text-white">-</span></td>
                                <td></td>
                            </tr>

                            <tr v-if="bookingPayments.standard_discount != '0'">
                                <td>{{ translator.descompte }}</td>
                                <td class="text-right">{{ bookingPayments.standard_discount | ereaseZero }} {{bookingPayments.currency}}</td>
                            </tr>
                            <tr v-if="bookingPayments.earlybooking_discount != '0'">
                                <td>{{ translator.descompteanticipat }}</td>
                                <td class="text-right">{{ bookingPayments.earlybooking_discount | ereaseZero }} {{bookingPayments.currency}}</td>
                            </tr>
                            <tr v-if="bookingPayments.longstay_discount != 0">
                                <td>{{ translator.descomptellargaestada }}</td>
                                <td class="text-right">{{ bookingPayments.longstay_discount | ereaseZero }} {{bookingPayments.currency}}</td>
                            </tr>
                            <tr v-if="bookingPayments.lastminute_discount != 0">
                                <td>{{ translator.descomptelastminute }}</td>
                                <td class="text-right">{{ bookingPayments.lastminute_discount | ereaseZero }} {{bookingPayments.currency}}</td>
                            </tr>
                            <tr v-if="bookingPayments.promotion_discount != 0">
                                <td>{{ translator.descomptepromocio }}</td>
                                <td class="text-right">{{ bookingPayments.promotion_discount | ereaseZero }} {{bookingPayments.currency}}</td>
                            </tr>
                            <tr v-if="bookingPayments.booking_fee != 0">
                                <td>{{ translator.carrecgestio }}</td>
                                <td class="text-right">{{ bookingPayments.booking_fee | ereaseZero }} {{bookingPayments.currency}}</td>
                            </tr>
                            <tr v-if="bookingPayments.cleaning_fee != 0">
                                <td>{{ translator.neteja }}</td>
                                <td class="text-right">{{ bookingPayments.cleaning_fee | ereaseZero }} {{bookingPayments.currency}}</td>
                            </tr>
                            <tr v-if="bookingPayments.checkin_fee != 0">
                                <td>{{ translator.carrecentrada }}</td>
                                <td class="text-right">{{ bookingPayments.checkin_fee | ereaseZero }} {{bookingPayments.currency}}</td>
                            </tr>
                            <tr v-if="bookingPayments.shortstay_fee != 0">
                                <td>{{ translator.carreccurtaestada }}</td>
                                <td class="text-right">{{ bookingPayments.shortstay_fee | ereaseZero }} {{bookingPayments.currency}}</td>
                            </tr>
                            <tr v-if="bookingPayments.tourist_tax != 0">
                                <td>{{ translator.taxaturistica }}</td>
                                <td class="text-right">{{ bookingPayments.tourist_tax | ereaseZero }} {{bookingPayments.currency}}</td>
                            </tr>

                            <tr v-if="bookingPayments.total_amount != 0">
                                <td><span class="text-white">-</span></td>
                                <td></td>
                            </tr>

                            <tr v-if="bookingPayments.total_amount != 0">
                                <td>{{ translator.total }}</td>
                                <td class="text-right">{{ bookingPayments.total_amount | ereaseZero }} {{bookingPayments.currency}}</td>
                            </tr>

                            <tr v-if="bookingPayments.pending_payment != 0 || bookingPayments.paid != 0">
                                <td><span class="text-white">-</span></td>
                                <td></td>
                            </tr>

                            <tr v-if="bookingPayments.pending_payment != 0">
                                <td>{{ translator.pendentpagament }}</td>
                                <td class="text-right">{{ bookingPayments.pending_payment | ereaseZero }} {{bookingPayments.currency}}</td>
                            </tr>
                            <tr v-if="bookingPayments.paid != 0">
                                <td>{{ translator.pagat }}</td>
                                <td class="text-right">{{ bookingPayments.paid | ereaseZero }} {{bookingPayments.currency}}</td>
                            </tr>

                            <tr v-if="bookingPayments.channel_commission != 0 || bookingPayments.channel_invoice != 0 || bookingPayments.owner_amount != 0 || bookingPayments.security_deposit != 0">
                                <td><span class="text-white">-</span></td>
                                <td></td>
                            </tr>

                            <tr v-if="bookingPayments.channel_commission != 0">
                                <td>{{ translator.comissiocanal }}</td>
                                <td class="text-right">{{ bookingPayments.channel_commission | ereaseZero }} {{bookingPayments.currency}}</td>
                            </tr>
                            <tr v-if="bookingPayments.channel_invoice != 0">
                                <td>{{ translator.comissiocanal }} {{ translator.factures }}</td><!-- falta la traducción correcta -->
                                <td class="text-right">{{ bookingPayments.channel_invoice | ereaseZero }} {{bookingPayments.currency}}</td>
                            </tr>
                            <tr v-if="bookingPayments.agency_amount != 0">
                                <td>{{ translator.importagencia }}</td>
                                <td class="text-right">{{ bookingPayments.agency_amount | ereaseZero }} {{bookingPayments.currency}}</td>
                            </tr>
                            <tr v-if="bookingPayments.owner_amount != 0">
                                <td>{{ translator.importpropietari }}</td>
                                <td class="text-right">{{ bookingPayments.owner_amount | ereaseZero }} {{bookingPayments.currency}}</td>
                            </tr>
                            <tr v-if="bookingPayments.security_deposit != 0">
                                <td>{{ translator.diposit }}</td>
                                <td class="text-right">{{ bookingPayments.security_deposit | ereaseZero }} {{bookingPayments.currency}}</td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
    
        </div>
  </div>
</template>

<script>
import NavbarOwner from "@/components/NavbarOwner.vue";
import { mapState } from "vuex";

export default {
    name: "OwnerBookingInfo",
    components: {
        NavbarOwner,
    },
    computed: {
        ...mapState("utils", ["user", "translator"]),
        ...mapState("owner", ["booking", "bookingPayments"]),
    },
    watch: { 
        translator() {
            this.$store.dispatch("owner/getBooking", { usr: this.$route.query.id, reserva: this.$route.params.bookingId });
            this.$store.dispatch("owner/getBookingPayments", { usr: this.$route.query.id, reserva: this.$route.params.bookingId });
        }
    },
    data() {
        return {
            isPreLoad: false,
            routeList: sessionStorage.getItem('listado')
        };
    },
    async created() {
        try {
            this.isPreLoad = true;
            if ( this.translator ) {
                await this.$store.dispatch("owner/getBooking", { usr: this.$route.query.id, reserva: this.$route.params.bookingId });
                await this.$store.dispatch("owner/getBookingPayments", { usr: this.$route.query.id, reserva: this.$route.params.bookingId });
            } else {
                await this.$store.dispatch("utils/getUser", { usr: this.$route.query.id });
            }
        } catch (error) {
            console.error(error);
        } finally {
            this.isPreLoad = false;
        }
    },
    methods: {
        routerLink() {
            this.$router.push({ name: `Owner${this.routeList}`, query: { id: this.$route.query.id }})
        }
    },
    filters: {
        ereaseZero(value) {
            if (!value) return "";
            value = String(value.includes(".00")) ? String(value).replace(".00", "") : '';
            return value;
        },
        transformDate(value) {
            if (!value) return '';
            const backendDate = value.split('-');
            return `${backendDate[2]}-${backendDate[1]}-${backendDate[0]}`;
        },
        toLower(value) {
            if (!value) return "";
            value = value.toString();
            return value.toLowerCase();
        },
    },

}
</script>

<style>
body {
    background-color: #f6f7f9;
}
.owner .table .line-head-info {
    height: 40px;
    color: var(--colores-gris-medio);
    border-bottom: 1.5px solid #F6F7F9;
}
.owner .table .line-head-info > td {
    border-top: none;
    vertical-align: middle;
}
.owner .table th, .table td {
    border-top: 1px solid #F6F7F9;
}
</style>